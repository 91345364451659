body {
  background-color: $body-bg;
}

.max-w-768 {
  max-width: 768px;
}

.tag-button {
  font-size: 0.7em;
}

@media screen and ( max-width: 400px ){
  li.page-item {
    display: none;
  }
  .page-item:first-child,
  .page-item:nth-child( 2 ),
  .page-item:nth-last-child( 2 ),
  .page-item:last-child,
  .page-item.active,
  .page-item.disabled {
    display: block;
  }
}

.article-body {
  img {
    max-width: 100%;
  }
}